import Link from 'next/link';
import React, { FC, useCallback } from 'react';

import { useNavigation } from './NavigationContext';

type Props = {
  title: string;
  link: string;
  onClick?: (event?: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const MobileNavigationItem: FC<Props> = ({
  link,
  onClick = () => undefined,
  title,
}) => {
  const { setMobileNavOpen } = useNavigation();

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick();
    }
    setMobileNavOpen(false);
  }, [onClick, setMobileNavOpen]);

  return (
    <li>
      <Link
        href={link}
        locale={false}
        passHref
        prefetch={false}
        className="flex items-center theme-dark:text-white text-lg"
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}>

        {title}

      </Link>
    </li>
  );
};
