import Link from 'next/link';
import React, { useCallback } from 'react';

import { TrackFooterLink } from '../../../utils/analytics';
import { Icon } from '@design-system';

export type FooterLinkProps = {
  isExternalLink?: boolean;
  link: string;
  onClick?: () => void;
  title: string;
};

export const FooterLink: React.FC<FooterLinkProps> = ({
  isExternalLink = false,
  link,
  onClick,
  title,
}) => {
  const handleClick = useCallback(() => {
    TrackFooterLink({
      linkTitle: title,
      linkUrl: link,
      isExternal: isExternalLink,
    });
    if (typeof onClick === 'function') {
      onClick();
    }
  }, [isExternalLink, link, onClick, title]);

  return (
    <li className="flex items-start">
      {isExternalLink ? (
        <a
          className="flex items-center group hover:text-neutral-4 theme-dark:hover:text-neutral-3 transition duration-300 ease-out space-x-1 py-1 overflow-hidden"
          href={link}
          onClick={handleClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="shrink-0">
            <Icon name="ic_arrow_top_right" />
          </span>
          <span className="flex-1 truncate">{title}</span>
        </a>
      ) : (
        (<Link
          href={link}
          locale={false}
          passHref
          prefetch={false}
          className="flex items-center group hover:text-neutral-4 theme-dark:hover:text-neutral-3 transition duration-300 ease-out space-x-1 py-1 overflow-hidden"
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
          tabIndex={0}>

          <span className="flex-1 truncate">{title}</span>

        </Link>)
      )}
    </li>
  );
};
