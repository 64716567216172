import dynamic from 'next/dynamic';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { KLARNA_COUNTRIES } from '../../../config/Klarna';
import {
  DEFAULT_PAYMENT_ORDER,
  IDEAL_COUNTRIES,
  PAYMENT_METHOD_IDEAL,
  PAYMENT_METHOD_KLARNA,
} from '../../../constants/payment';
import { useCountryCode } from '../../../hooks/useCountryCode';
import { Image } from '../../Image';

const FooterLocale = dynamic(() => import('./FooterLocale'), { ssr: false });

export const FooterPaymentMethods: FC = () => {
  const { t } = useTranslation('footer');

  const [countryCode] = useCountryCode();
  const [paymentOrder, setPaymentOrder] = useState(DEFAULT_PAYMENT_ORDER);

  const excludePaymentMethods = (methods: Array<string>) => {
    const updatedPaymentOrder = DEFAULT_PAYMENT_ORDER.filter(
      (payment) => !methods.includes(payment),
    );
    return updatedPaymentOrder;
  };

  useEffect(() => {
    const excludedPaymentMethods: Array<string> = [];
    if (!KLARNA_COUNTRIES.includes(countryCode)) {
      excludedPaymentMethods.push(PAYMENT_METHOD_KLARNA);
    }
    if (!IDEAL_COUNTRIES.includes(countryCode)) {
      excludedPaymentMethods.push(PAYMENT_METHOD_IDEAL);
    }

    const order = excludePaymentMethods(excludedPaymentMethods);
    setPaymentOrder(order);
  }, [countryCode]);

  return (
    <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-y-6 md:gap-y-0 md:gap-x-3">
      <div className="flex-1 w-full flex flex-wrap gap-2">
        {paymentOrder.map((payment) => (
          <div key={payment} className="border border-divider/20 w-14">
            <Image
              alt={payment}
              className="overflow-hidden rounded-sm"
              size={{ sm: { ratio: 'intrinsic', span: 12 } }}
              src={t(`payment.${payment}`)}
              unoptimized
              width={64}
            />
          </div>
        ))}
      </div>

      <div className="shrink-0">
        <FooterLocale />
      </div>
    </div>
  );
};
