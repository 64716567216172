import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
};

const PageSection: React.FC<Props> = ({
  children,
  className,
  fullWidth = false,
}) => {
  return (
    <div
      className={classnames(
        'w-full relative',
        {
          'wrapper-website': !fullWidth,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default PageSection;
