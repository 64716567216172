import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';

type DesktopNavigationItemProps = {
  className?: string;
  link: string;
  onClick?: (
    event?:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>,
  ) => void;
  title: string;
};

export const DesktopNavigationItem: FC<DesktopNavigationItemProps> = ({
  className,
  link,
  onClick,
  title,
}) => (
  (<Link
    href={link}
    locale={false}
    prefetch={false}
    className={classNames(
      'lg:inline-block hover:text-neutral-4 theme-dark:text-light theme-dark:hover:text-neutral-3',
      className,
    )}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex={0}>

    {title}

  </Link>)
);
