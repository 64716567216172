import { useTranslation } from 'next-i18next';
import React from 'react';

import { pathFor } from '../../../helpers/links';
import { TrackNavbarLinkClicked } from '../../../utils/analytics';
import { DesktopNavigationItem } from './DesktopNavigationItem';

export const DesktopNavigation = () => {
  const { t } = useTranslation('navigation');

  const items = [
    {
      context: 'works',
      href: pathFor({ page: 'WORKS_INDEX' }),
      title: t('works'),
    },
    {
      context: 'artists',
      href: pathFor({ page: 'ARTISTS_INDEX' }),
      title: t('artists'),
    },
    {
      context: 'stories',
      href: pathFor({ page: 'INSIGHTS_INDEX' }),
      title: t('stories'),
    },
    {
      context: 'about',
      href: pathFor({ page: 'ABOUT' }),
      title: t('about'),
    },
  ];

  return (
    <div className="hidden md:flex justify-start items-center space-x-4 relative z-[90]">
      {items.map((item) => (
        <DesktopNavigationItem
          key={item.context}
          link={item.href}
          onClick={() => {
            TrackNavbarLinkClicked({
              context: item.context,
              title: item.title,
            });
          }}
          title={item.title}
        />
      ))}
    </div>
  );
};
