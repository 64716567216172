import classNames from 'classnames';
import { FC } from 'react';

interface DividerProps {
  className?: string;
  variant?: 'pageCenter' | 'fullWidth';
  appearance?: 'solid' | 'dotted' | 'dashed';
}

export const Divider: FC<DividerProps> = ({
  appearance = 'solid',
  className,
  variant = 'pageCenter',
}) => (
  <div
    className={classNames(className, {
      'wrapper-website': variant === 'pageCenter',
      'w-full': variant === 'fullWidth',
    })}
  >
    {appearance === 'solid' && <hr className="border-divider/20" />}
    {appearance === 'dashed' && (
      <hr
        className={classNames('h-0.5 w-full border-none')}
        style={{
          backgroundPosition: '0 0',
          backgroundRepeat: 'repeat-x',
          backgroundSize: '8px 1px',
          backgroundImage:
            'linear-gradient(to right, rgb(139 121 101 / 0.2) 50%, transparent 50%)',
        }}
      />
    )}
    {appearance === 'dotted' && (
      <hr
        className={classNames('h-0.5 w-full border-none')}
        style={{
          backgroundPosition: '-2px -2px',
          backgroundRepeat: 'repeat-x',
          backgroundSize: '6px 6px',
          backgroundImage:
            'radial-gradient(ellipse, rgb(139 121 101 / 0.2) 0px, rgb(139 121 101 / 0.2) 1px, transparent 1px)',
        }}
      />
    )}
  </div>
);
