import React from 'react';

import { useAuth } from '../../providers';
import { SignedInSubscription } from './SignedInSubscription';
import { SignUpAndSubscribe } from './SignUpAndSubscribe';
import { GeneralSubscriptionProps } from './types';

export const GeneralSubscription: React.FC<GeneralSubscriptionProps> = (
  props,
) => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? (
    <SignedInSubscription {...props} />
  ) : (
    <SignUpAndSubscribe {...props} />
  );
};
