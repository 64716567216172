import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';

import { pathFor } from '../../../helpers/links';
import { TrackNavbarLinkClicked } from '../../../utils/analytics';
import { Footer } from '../Footer';
import { MobileNavigationItem } from './MobileNavigationItem';
import { useNavigation } from './NavigationContext';
import { Divider } from '../../Divider/Divider';
import { Button } from '@design-system';

interface Props {
  hideFooter?: boolean;
  isDarkMode?: boolean;
}

export const MobileNavigation: React.FC<Props> = ({
  hideFooter = false,
  isDarkMode = false,
}) => {
  const { t } = useTranslation('navigation');
  const { mobileNavOpen, setMobileNavOpen } = useNavigation();

  // Lock the window frame to prevent background scrolling
  useEffect(() => {
    if (mobileNavOpen) {
      document.body.classList.add('pageLocked');
    } else {
      document.body.classList.remove('pageLocked');
    }
  }, [mobileNavOpen]);

  return (
    <>
      <div className="relative z-90 md:hidden">
        <Button
          hideLabel
          label={mobileNavOpen ? 'Close menu' : 'Open menu'}
          icon={mobileNavOpen ? 'ic_close' : 'ic_menu'}
          size="sm"
          variant="transparent"
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
        />
      </div>
      <div
        className={classNames(
          isDarkMode ? 'bg-dark' : 'bg-light',
          'fixed left-0 w-full navbar-menu top-14 md:hidden',
          {
            'hidden': !mobileNavOpen,
            'h-full': !hideFooter,
          },
        )}
      >
        <div
          className={classNames('w-full h-full overflow-x-scroll', {
            'pb-18': !hideFooter,
            'border-t border-divider/20': hideFooter,
          })}
        >
          <ul className="wrapper-website flex flex-col gap-3 px-4 py-8">
            <MobileNavigationItem
              link="/"
              onClick={() => {
                TrackNavbarLinkClicked({
                  context: 'home',
                  title: t('home'),
                });
              }}
              title={t('home')}
            />
            <MobileNavigationItem
              link={pathFor({ page: 'WORKS_INDEX' })}
              onClick={() => {
                TrackNavbarLinkClicked({
                  context: 'works',
                  title: t('works'),
                });
              }}
              title={t('works')}
            />
            <MobileNavigationItem
              link={pathFor({ page: 'ARTISTS_INDEX' })}
              onClick={() => {
                TrackNavbarLinkClicked({
                  context: 'artists',
                  title: t('artists'),
                });
              }}
              title={t('artists')}
            />
            <MobileNavigationItem
              link={pathFor({ page: 'INSIGHTS_INDEX' })}
              onClick={() => {
                TrackNavbarLinkClicked({
                  context: 'stories',
                  title: t('stories'),
                });
              }}
              title={t('stories')}
            />
            <MobileNavigationItem
              link="/about"
              onClick={() => {
                TrackNavbarLinkClicked({
                  context: 'about',
                  title: t('about'),
                });
              }}
              title={t('about')}
            />
          </ul>
          {!hideFooter && mobileNavOpen && (
            <div className="wrapper-website p-5 pt-0">
              <Divider variant="fullWidth" />
              <Footer />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
