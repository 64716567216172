import { Alert, Button } from '@design-system';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { isValidEmail } from '../../helpers/email';
import { pathFor } from '../../helpers/links';
import { useGeneralSubscription } from '../../hooks/useGeneralSubscription';
import { TrackEmailSubscription } from '../../utils/analytics';
import {
  LazyCloudflareTurnstile,
  useResetTurnstile,
} from '../Captcha/LazyCloudflareTurnstile';
import { InputField } from '../Forms';
import UnderlinedLink from '../UnderlinedLink';
import { GeneralSubscriptionProps } from './types';

export const SignUpAndSubscribe: React.FC<GeneralSubscriptionProps> = ({
  analyticsButtonLocation,
  inlineInputs,
  legalsDefault = false,
  source,
  variant = 'primary',
}) => {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [showLegals, setShowLegals] = useState(legalsDefault);
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState<boolean>();
  const [captchaToken, setCaptchaToken] = useState<string>();
  const { turnstileRef: turnstileRefOne, turnstileReset: turnstileResetOne } =
    useResetTurnstile();
  const { turnstileRef: turnstileRefTwo, turnstileReset: turnstileResetTwo } =
    useResetTurnstile();

  const { loading, start } = useGeneralSubscription({
    buttonLocation: analyticsButtonLocation,
    source,
  });

  const { t } = useTranslation(['footer', 'subscriptions']);

  const canSubmitEmail = isValidEmail(email) && captchaToken;

  const success = Boolean(response);

  useEffect(() => {
    if (!success) return;

    TrackEmailSubscription({
      mode: 'GENERAL',
      type: 'success',
      buttonLocation: analyticsButtonLocation,
      generalMailList: true,
      subscribed: true,
      hasJustSignedUp: true,
      trackEvent: false,
    });
  }, [analyticsButtonLocation, success]);

  async function handleSubmit() {
    if (loading) return;

    TrackEmailSubscription({
      mode: 'GENERAL',
      type: 'submit',
      buttonLocation: analyticsButtonLocation,
      generalMailList: true,
      subscribed: true,
    });

    try {
      const authResponse = await start(email, captchaToken);
      if (authResponse.success) {
        setResponse(true);
      }
    } catch (e) {
      setResponse(false);
      turnstileResetOne();
      turnstileResetTwo();
      return;
    }
  }

  if (success) {
    return (
      <Alert $status="success">
        {t('subscriptions:generalForm.successAlertBrief')}
      </Alert>
    );
  }

  return (
    <div className="w-full flex flex-col">
      {response === false && (
        <Alert $status="error" className="mb-3">
          {t('subscriptions:generalForm.failureAlertBrief')}
        </Alert>
      )}

      <div
        className={classNames('flex flex-1 w-full gap-3', {
          'flex-col': !inlineInputs,
          'flex-col sm:flex-row w-full sm:items-center': inlineInputs,
        })}
      >
        <div className={classNames('w-full')}>
          <InputField
            disabled={loading}
            id="sign-up-email"
            name="sign-up-email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            onFocus={() => {
              setShowCaptcha(true);
              setShowLegals(true);
            }}
            placeholder={t(
              'subscriptions:enhanced.steps.signUpEmail.fields.email.label',
            )}
            type="email"
            value={email}
          />
        </div>

        {!inlineInputs && showCaptcha && (
          <LazyCloudflareTurnstile
            actionName="sign_up_and_subscribe"
            className="mb-3"
            onError={() => setCaptchaToken(undefined)}
            onExpire={() => setCaptchaToken(undefined)}
            onSuccess={setCaptchaToken}
            turnstileRef={turnstileRefOne}
          />
        )}

        <div className="flex-1 sm:flex-none">
          <Button
            disabled={success || loading || !canSubmitEmail}
            fullWidth
            icon={success ? 'ic_check' : undefined}
            label={success ? t('signUpSuccess') : t('signUpButton')}
            loading={loading}
            onClick={() => void handleSubmit()}
            size={inlineInputs ? 'md' : 'lg'}
            variant={success ? 'positive' : variant}
          />
        </div>
      </div>

      {inlineInputs && showCaptcha && (
        <LazyCloudflareTurnstile
          actionName="sign_up_and_subscribe"
          className="my-3"
          onError={() => setCaptchaToken(undefined)}
          onExpire={() => setCaptchaToken(undefined)}
          onSuccess={setCaptchaToken}
          turnstileRef={turnstileRefTwo}
        />
      )}

      {showLegals && (
        <p
          className={classNames(
            'text-neutral-4 text-xs mt-3 px-3 text-center',
            {
              'px-0': inlineInputs,
            },
          )}
        >
          <Trans i18nKey="subscriptions:enhanced.steps.signUp.caption" t={t}>
            By continuing, you’re agreeing to our&nbsp;
            <UnderlinedLink
              href={pathFor({ page: 'TERMS_AND_CONDITIONS' })}
              target="_blank"
            >
              terms &amp; conditions
            </UnderlinedLink>
            <UnderlinedLink
              href={pathFor({ page: 'PRIVACY_POLICY' })}
              target="_blank"
            >
              privacy policy
            </UnderlinedLink>
            .
          </Trans>
        </p>
      )}

      {inlineInputs && showCaptcha && (
        <LazyCloudflareTurnstile
          actionName="sign_up_and_subscribe"
          onError={() => setCaptchaToken(void 0)}
          onExpire={() => setCaptchaToken(void 0)}
          onSuccess={setCaptchaToken}
          turnstileRef={turnstileRefTwo}
        />
      )}
    </div>
  );
};
