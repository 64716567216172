import Head from 'next/head';
import React from 'react';

const ogImageUrl = new URL(process.env.NEXT_PUBLIC_HOSTNAME!);
ogImageUrl.pathname = '/images/social.png';

const DEFAULT_IMAGE_URL = ogImageUrl.toString();
const DEFAULT_TYPE = 'website';

type Props = {
  title: string;
  description: string;
  url: string;
  imageUrl?: string;
  type?: 'article' | 'website';
  hostname?: string;
};

const SocialMetadata: React.FC<Props> = ({
  title,
  description,
  type = DEFAULT_TYPE,
  imageUrl = DEFAULT_IMAGE_URL,
  url,
  hostname,
}) => {
  return (
    <Head>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta property="twitter:domain" content={hostname || 'avantarte.com'} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />

      <meta
        name="facebook-domain-verification"
        content="38k1l0uq3tn9o3yld2lihc79kuvybf"
      />

      <meta
        name="google-site-verification"
        content="tBwBcGldly0LIou-4SQNwINDwbeiFTyxgy3-i8NtXmU"
      />
    </Head>
  );
};

export default SocialMetadata;
