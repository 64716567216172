import Link from 'next/link';
import React, { useState } from 'react';
import { useRouter } from 'next/router';

import { Avatar, Button } from '@design-system';
import { useTranslation } from 'next-i18next';
import { pathFor } from '../../../helpers/links';
import { useAuth, useFlow } from '../../../providers';
import { TrackNavbarLinkClicked } from '../../../utils/analytics';
import { AnalyticsObjectProvider } from '../../../utils/analytics/object';
import { ClickOutside } from '../../ClickOutside';
import { AuthEntryPoint, AuthFlow } from '../../../features/auth/types';
import { buildSignInFlow } from '../../../features/auth';

interface Props {
  isDarkMode?: boolean;
}

export const AccountNavigationItem: React.FC<Props> = () => {
  const { t } = useTranslation('navigation');
  const [showDropdown, setShowDropdown] = useState(false);
  const { isLoggedIn, user, logout } = useAuth();
  const { startFlow } = useFlow();
  const { pathname } = useRouter();

  const handleLogout = () => void logout();

  return (
    <AnalyticsObjectProvider name="account menu" sendViewedEvent={false}>
      {(emitter) => (
        <React.Fragment>
          {isLoggedIn && user ? (
            <>
              <ClickOutside
                className="flex relative"
                onClickOutside={() => {
                  // Only fire this event if we're closing the dropdown
                  if (showDropdown) emitter('close', 'clicked');

                  setShowDropdown(false);
                }}
              >
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    emitter(showDropdown ? 'close' : 'open', 'clicked');
                    setShowDropdown(!showDropdown);
                  }}
                  type="button"
                >
                  <Avatar
                    email={user.email ?? ''}
                    src={user.avatar}
                    userName={user.username}
                  />
                </button>

                {showDropdown && (
                  <div className="absolute top-10 ms:top-12 right-0 w-44 rounded-sm shadow-card bg-light border border-divider/20 divide-y divide-divider/20">
                    <ul className="p-2">
                      {
                        <li>
                          <Link
                            href={pathFor({ page: 'ACCOUNT_OVERVIEW' })}
                            className="block px-2 py-2 hover:bg-neutral-1 cursor-pointer rounded-sm"
                            onClick={() => {
                              emitter('option', 'clicked', {
                                title: 'overview',
                              });
                              setShowDropdown(false);
                            }}
                          >
                            {t('profile.dropdown.account')}
                          </Link>
                        </li>
                      }
                      <li>
                        <Link
                          href={pathFor({ page: 'ACCOUNT_DETAILS' })}
                          className="block px-2 py-2 hover:bg-neutral-1 cursor-pointer rounded-sm"
                          onClick={() => {
                            emitter('option', 'clicked', {
                              title: 'personal details',
                            });
                            setShowDropdown(false);
                          }}
                        >
                          {t('profile.dropdown.personalDetails')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={pathFor({ page: 'ACCOUNT_ONBOARDING' })}
                          className="block px-2 py-2 hover:bg-neutral-1 cursor-pointer rounded-sm"
                          onClick={() => {
                            emitter('option', 'clicked', {
                              title: 'interests',
                            });
                            setShowDropdown(false);
                          }}
                        >
                          {t('profile.dropdown.interests')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={pathFor({ page: 'ACCOUNT_ORDERS' })}
                          className="block px-2 py-2 hover:bg-neutral-1 cursor-pointer rounded-sm"
                          onClick={() => {
                            emitter('option', 'clicked', { title: 'orders' });
                            setShowDropdown(false);
                          }}
                        >
                          {t('profile.dropdown.orders')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={pathFor({ page: 'ACCOUNT_DRAW_ENTRIES' })}
                          className="block px-2 py-2 hover:bg-neutral-1 cursor-pointer rounded-sm"
                          onClick={() => {
                            emitter('option', 'clicked', {
                              title: 'draw entries',
                            });
                            setShowDropdown(false);
                          }}
                        >
                          {t('profile.dropdown.drawEntries')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={pathFor({ page: 'ACCOUNT_ADDRESSES' })}
                          className="block px-2 py-2 hover:bg-neutral-1 cursor-pointer rounded-sm"
                          onClick={() => {
                            emitter('option', 'clicked', {
                              title: 'addresses',
                            });
                            setShowDropdown(false);
                          }}
                        >
                          {t('profile.dropdown.addresses')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={pathFor({ page: 'ACCOUNT_PAYMENT_METHODS' })}
                          className="block px-2 py-2 hover:bg-neutral-1 cursor-pointer rounded-sm"
                          onClick={() => {
                            emitter('option', 'clicked', {
                              title: 'payment methods',
                            });
                            setShowDropdown(false);
                          }}
                        >
                          {t('profile.dropdown.paymentMethods')}
                        </Link>
                      </li>
                    </ul>
                    <ul className="p-2">
                      <li
                        tabIndex={0}
                        role="button"
                        className="px-2 py-2 hover:bg-neutral-1 cursor-pointer rounded-sm"
                        onKeyDown={(event) => {
                          if (event.key !== 'Enter') return;

                          emitter('option', 'clicked', { title: 'sign out' });
                          handleLogout();
                        }}
                        onClick={() => {
                          emitter('option', 'clicked', { title: 'sign out' });
                          handleLogout();
                        }}
                      >
                        {t('profile.dropdown.signOut')}
                      </li>
                    </ul>
                  </div>
                )}
              </ClickOutside>
            </>
          ) : (
            <Button
              label="Sign In"
              hideLabel
              icon="ic_account"
              size="sm"
              variant="transparent"
              onClick={() => {
                TrackNavbarLinkClicked({
                  context: 'signin',
                  title: t('signin'),
                });
                startFlow({
                  name: AuthFlow.SignIn,
                  steps: buildSignInFlow({
                    optInGeneral: false,
                    skipProfileStep: pathname === '/aml',
                  }),
                  analyticsProperties: { source: AuthEntryPoint.NavBar },
                });
              }}
            />
          )}
        </React.Fragment>
      )}
    </AnalyticsObjectProvider>
  );
};
