import { useContext } from 'react';
import { CookieConsentState } from '../types/UserPreferences';
import UserPreferencesContext from '../context/UserPreferencesContext';

type UseCookieConsentHook = () => [
  CookieConsentState,
  (unit: CookieConsentState) => void,
];

export const useCookieConsent: UseCookieConsentHook = () => {
  const { preferences, setCookieConsent } = useContext(UserPreferencesContext);
  return [preferences.cookieConsent, setCookieConsent];
};
