import React from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useCookieConsent } from '../../../hooks/useCookieConsent';
import { Button } from '@design-system';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import PageSection from '../../Layout/PageSection';

const CookieConsent: React.FC = () => {
  const { t } = useTranslation('common');
  const [_, setCookieConsent] = useCookieConsent();
  const isMobile = useMediaQuery('sm');

  const handleConfirmation = () => {
    setCookieConsent('GRANTED');
  };

  return (
    <div className="theme-light bg-neutral-2 py-3">
      <PageSection>
        <div
          data-testid="cookie-consent"
          className="flex flex-col sm:flex-row justify-center items-center gap-3"
        >
          <div className="flex-1 max-w-sm sm:max-w-full w-full text-center sm:text-left">
            <Trans t={t} i18nKey="cookieConsentMessage">
              We use cookies and may process personal data. To find out more,
              check our&nbsp;
              <a
                href="/support/cookie-policy"
                className="cursor-pointer underline hover:no-underline"
                target="_blank"
              >
                cookie policy
              </a>
            </Trans>
          </div>
          <div className="shrink-0">
            <Button
              fullWidth={isMobile}
              size="md"
              label={t('cookieConsentConfirmAction')}
              onClick={handleConfirmation}
              variant="primary"
            />
          </div>
        </div>
      </PageSection>
    </div>
  );
};

export default CookieConsent;
