import NextHead from 'next/head';

import { aaColors } from '@design-system';
import { truncateString } from '../../utils/strings';
import { LayoutTheme } from './types';

type Props = {
  title: string;
  description?: string;
  urls?: { canonical?: string };
  theme?: LayoutTheme;
};

const Head = ({
  title = '',
  description = '',
  urls: { canonical } = {},
  theme = 'light',
}: Props) => {
  const mapThemeFavicon = {
    light: '/favicon.ico?v=2',
    dark: '/favicon-dark.ico?v=2',
    'light-grey': '/favicon.ico?v=2',
    'dark-grey': '/favicon.ico?v=2',
  };

  const mapThemeIcon16 = {
    light: '/favicon-16x16.png',
    dark: '/favicon-dark-16x16.png',
    'light-grey': '/favicon-16x16.png',
    'dark-grey': '/favicon-16x16.png',
  };

  const mapThemeIcon32 = {
    light: '/favicon-32x32.png',
    dark: '/favicon-dark-32x32.png',
    'light-grey': '/favicon-32x32.png',
    'dark-grey': '/favicon-32x32.png',
  };

  const mapThemeColor = {
    light: '#ffffff',
    dark: aaColors.neutral[6],
    'light-grey': '#ffffff',
    'dark-grey': '#000000',
  };

  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <title>{title}</title>
      <meta name="description" content={truncateString(description, 160)} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link
        rel="preload"
        as="font"
        href="/fonts/RimaAvant-Arte-Regular.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/RimaAvant-Arte-Bold.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffffff" />
      <link rel="shortcut icon" href={mapThemeFavicon[theme]} />
      <meta
        name="msapplication-TileColor"
        content={mapThemeColor[theme]}
      ></meta>
      <meta name="theme-color" content={mapThemeColor[theme]}></meta>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={mapThemeIcon32[theme]}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={mapThemeIcon16[theme]}
      />

      <link
        rel="preconnect"
        href="https://a.storyblok.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://gtm.svc.avantarte.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://images.avantarte.org"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://api.svc.avantarte.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://cdn.growthbook.io"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://store.avantarte.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://cdn.shopify.com"
        crossOrigin="anonymous"
      />

      {canonical && <link rel="canonical" href={canonical} />}
    </NextHead>
  );
};

export default Head;
